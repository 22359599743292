<template>
  <div class="row mx-lg-0 overflow_mobile align-items-start justify-content-center">
    <div class="col-12 col-lg-auto p-lg-0" v-for="item, key in navItems" :key="key">
      <NavItem :item="item" @closeMenu="close" />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    NavItem: defineAsyncComponent(() => import('./NavItem.vue'))
  },
  name: 'MenuItems',
  emits: ['closeMenu'],
  data () {
    return {
      isActive: false,
      navItemsZA: [
        // {
        //   routeName: 'Our Work',
        //   displayName: 'Our Work',
        //   link: 'ourwork',
        //   isActive: false
        // },
        {
          routeName: 'Appeals',
          displayName: 'Appeals',
          link: 'appeals/0',
          isActive: false
        },
        {
          routeName: 'About Us',
          displayName: 'About',
          link: 'aboutus',
          isActive: false
        },
        {
          routeName: 'Contact Us',
          displayName: 'Contact',
          link: 'contact',
          isActive: false
        },
        {
          routeName: 'Donation Methods',
          displayName: 'Ways to Donate',
          link: 'waystodonate',
          isActive: false
        },
        {
          routeName: 'Donation Methods',
          displayName: 'More',
          link: 'waystodonate',
          isActive: false,
          sub: [
            // { routeName: 'Donation Methods', displayName: 'Ways to Donate', link: 'waystodonate', isActive: false },
            { routeName: 'Zakaah Calculator', displayName: 'Zakaah Calculator', link: 'zakaahcalculator/new', isActive: false },
            { routeName: 'Volunteer', displayName: 'Volunteer', link: 'volunteer', isActive: false },
            { routeName: 'Section 18A', displayName: 'Section 18A', link: 'section18a', isActive: false },
            // { routeName: 'Contact Us', displayName: 'Contact', link: 'contact', isActive: false },
            { routeName: 'App Download', displayName: 'App Download', link: 'appdownload', isActive: false }
          ]
        }
      ],
      navItemsUK: [
        // {
        //   routeName: 'Our Work',
        //   displayName: 'Our Work',
        //   link: 'ourwork',
        //   isActive: false
        // },
        {
          routeName: 'Appeals',
          displayName: 'Appeals',
          link: 'appeals/0',
          isActive: false
        },
        {
          routeName: 'News',
          displayName: 'Articles',
          link: 'news',
          isActive: false
        },
        {
          routeName: 'About Us',
          displayName: 'About',
          link: 'aboutus',
          isActive: false
        },
        {
          routeName: 'Contact Us',
          displayName: 'Contact',
          link: 'contact',
          isActive: false
        },
        {
          routeName: 'Donation Methods',
          displayName: 'More',
          link: 'waystodonate',
          isActive: false,
          sub: [
            { routeName: 'Donation Methods', displayName: 'Ways to Donate', link: 'waystodonate', isActive: false },
            // { routeName: 'Zakaah Calculator', displayName: 'Zakaah Calculator', link: 'zakaahcalculator/new', isActive: false },
            { routeName: 'Volunteer', displayName: 'Volunteer', link: 'volunteer', isActive: false },
            { routeName: 'Verified Fundraisers', displayName: 'Verified Fundraisers', link: 'verifiedfundraisers', isActive: false },
            { routeName: 'The Gift Aid Scheme', displayName: 'GiftAid', link: 'giftaid', isActive: false }
            // { routeName: 'Contact Us', displayName: 'Contact', link: 'contact', isActive: false },
          ]
        }
      ]
    }
  },
  mounted () {
    if (this.theCurrentRoute) {
      this.clearAllActiveItems()
      this.checkActiveItem()
    }
  },
  watch: {
    theCurrentRoute () {
      if (this.theCurrentRoute) {
        this.clearAllActiveItems()
        this.checkActiveItem()
      }
    }
  },
  methods: {
    close () {
      this.$emit('closeMenu')
    },
    clearAllActiveItems () {
      this.navItems.forEach(item => {
        item.isActive = false
        if (item.sub) {
          item.sub.forEach(subitem => {
            subitem.isActive = false
          })
        }
      })
    },
    setActiveItem (val) {
      this.navItems[val].isActive = true
    },
    setActiveSubItem (mainindex, subindex) {
      this.navItems[mainindex].sub[subindex].isActive = true
    },
    checkActiveItem () {
      const index = this.navItems.findIndex(x => x.routeName === this.$route.name)
      if (index > -1) {
        this.setActiveItem(index)
      } else {
        const mainindex = this.navItems.findIndex(x => x.sub && x.sub.findIndex(y => y.routeName === this.$route.name))
        if (mainindex > -1) {
          const subindex = this.navItems[mainindex].sub.findIndex(x => x.routeName === this.$route.name)
          if (subindex > -1) {
            this.setActiveItem(mainindex)
            this.setActiveSubItem(mainindex, subindex)
          }
        }
      }
    }
  },
  computed: {
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    },
    theCurrentRoute () {
      return this.$route
    },
    navItems () {
      let theItems = []
      if (this.isUK) {
        theItems = this.navItemsUK
      } else {
        theItems = this.navItemsZA
      }
      return theItems
    }
  }
}
</script>

<style scoped>
  .overflow_mobile {
    max-height: calc(100vh - 150px);
    overflow-y: auto;
  }
  @media (min-width: 992px) {
    .overflow_mobile {
      height: inherit;
      overflow-y: unset;
    }
  }
</style>
